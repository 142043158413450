import { Menu } from "@headlessui/react";
import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { baseVrbt } from "app/constants";
import { useAuth } from "app/AuthContext";
import HelpPopUp from "modals/vrbt/HelpPopUp";

const HeaderNavDropDown = () => {
  const { user } = useAuth();
  const levelId = user.type;

  const [isOpened, setIsOpened] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    localStorage.clear();
  };

  return (
    <>
      <Menu as="div" className="relative font-poppins text-sm z-10">
        <Menu.Button
          as="button"
          className="p-2 bg-white rounded-full drop-shadow"
        >
          <BiChevronDown />
        </Menu.Button>

        {levelId === 1 || levelId === "1" ? (
          <Menu.Items
            as="div"
            className="absolute right-0 mt-2 p-4 w-[200px] rounded-[10px] bg-white drop-shadow flex flex-col gap-4"
          >
            <Menu.Item as="div">
              <Link to={`${baseVrbt}`} onClick={handleLogout}>
                Sign Out
              </Link>
            </Menu.Item>
          </Menu.Items>
        ) : (
          <Menu.Items
            as="div"
            className="absolute right-0 mt-2 p-4 w-[200px] rounded-[10px] bg-white drop-shadow flex flex-col gap-4"
          >
            <Menu.Item as="div">
              <Link to={baseVrbt + "dashboard/account"}>My Account</Link>
            </Menu.Item>
            <Menu.Item as="div">
              <p className="cursor-pointer" onClick={() => setIsOpened(true)}>
                Help
              </p>
            </Menu.Item>
            <Menu.Item as="div" className="border"></Menu.Item>
            <Menu.Item as="div">
              <Link to={`${baseVrbt}`} onClick={handleLogout}>
                Sign Out
              </Link>
            </Menu.Item>
          </Menu.Items>
        )}
      </Menu>

      {/* help pop up */}
      <HelpPopUp isOpen={isOpened} setIsOpenParse={setIsOpened} />
    </>
  );
};

export default HeaderNavDropDown;

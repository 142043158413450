import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CustomLink from "./CustomLink";
import { Img } from "components";
import { baseVrbt } from "app/constants";

const SideNavMobile = ({ open, close, menus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  const toggleDropdown = (feature_name) => {
    setOpenMenu(openMenu === feature_name ? null : feature_name);
  };

  return (
    <>
      <Transition show={open}>
        <Transition.Child
          as="Fragment"
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30"></div>
        </Transition.Child>
        <Transition.Child
          as="Fragment"
          enter="transition ease-out duration-100 transform"
          enterFrom="translate-x-[-280px]"
          enterTo="translate-x-0"
          leave="transition ease-in duration-75 transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-[-280px]"
        >
          <div className="fixed top-0 right-0 bottom-0 flex flex-col gap-4 p-5 border-r w-[280px] bg-white_A700 z-50">
            <div className="bg-white_A700 border-b w-[259px] border-gray_200 border-solid flex flex-wrap justify-between items-center mx-auto max-w-screen-xlw-full">
              <div className="flex flex-row gap-3.5 items-center mb-3 justify-start self-stretch w-auto">
                <Img
                  src={process.env.PUBLIC_URL + "/images/vrbt/ic_logo_vrbt.svg"}
                  className="h-[50px] w-[180px] ml-4 object-cover"
                  alt="logo"
                />
              </div>
            </div>
            {/* {menus.map((data) => (
              <CustomLink
                key={data.feature_code}
                to={
                  data.feature_name === "Dashboard"
                    ? baseVrbt + "dashboard"
                    : baseVrbt +
                      "dashboard/" +
                      data.feature_name.toLowerCase().replace(/\s/g, "-")
                }
                title={data.feature_name}
                iconActiveURL={data.icon_active}
                iconInActiveURL={data.icon_incative}
              />
            ))} */}

            {menus.map((data) => (
              <MenuItem
                key={data.feature_name}
                data={data}
                isOpen={openMenu === data.feature_name}
                sub={data.feature_name}
                toggleDropdown={toggleDropdown}
              />
            ))}
          </div>
          <div className="fixed top-5 left-7">
            <button type="button" onClick={close}>
              <AiOutlineCloseCircle className="w-10 h-10 bg-white rounded-full" />
            </button>
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
};

const MenuItem = ({ data, isOpen, sub, toggleDropdown }) => {
  return (
    <div>
      <div onClick={() => toggleDropdown(data.feature_name)}>
        <CustomLink
          key={data.feature_name}
          to={
            data.feature_name === "Dashboard"
              ? baseVrbt + "dashboard"
              : baseVrbt +
                "dashboard/" +
                data.feature_name.toLowerCase().replace(/\s/g, "-")
          }
          title={data.feature_name}
          iconActiveURL={data.icon_active}
          iconInActiveURL={data.icon_inactive}
          isSubMenu={false}
          isParent={data.feature_name === "Content Management"}
        />
      </div>

      {isOpen && (
        <div>
          {data.sub_menu &&
            data.sub_menu.map((subItem) => (
              <CustomLink
                key={subItem.feature_name}
                to={`${baseVrbt}dashboard/${sub
                  .toLowerCase()
                  .replace(/\s/g, "-")}/${subItem.feature_name
                  .toLowerCase()
                  .replace(/\s/g, "-")}`}
                title={subItem.feature_name}
                iconActiveURL={subItem.icon_active}
                iconInActiveURL={subItem.icon_inactive}
                isSubMenu={true}
                isParent={false}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default SideNavMobile;

import { baseVrbt } from "app/constants";
import { Img, Text } from "components";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const CustomLink = ({
  iconActiveURL,
  iconInActiveURL,
  title,
  to,
  isSubMenu,
  isParent,
}) => {
  const location = useLocation();
  const [isCleaned, setCleaned] = useState(true);

  const checkPath = (path) => {
    if (location.pathname.endsWith("/")) {
      setCleaned(false);
    }

    if (!isCleaned) {
      const newPath = location.pathname.slice(0, -1);
      if (newPath === path) {
        return true;
      }
    }

    if (location.pathname === path) {
      return true;
    }
  };

  const checkPathSub = () =>
    (location.pathname === baseVrbt + "dashboard/content-management" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/artist/talent" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-list" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-list/create" ||
      location.pathname ===
        baseVrbt +
          "dashboard/content-management/content-list/content-list/preview" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-group" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-class" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/detail/") &&
    isParent
      ? true
      : false;

  const isActive = () => {
    return location.pathname === to || location.pathname.startsWith(`${to}/`);
  };

  const validatePath = () =>
    (location.pathname === baseVrbt + "dashboard/content-management" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/artist/talent" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-list" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-list/create" ||
      location.pathname ===
        baseVrbt +
          "dashboard/content-management/content-list/content-list/preview" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-group" ||
      location.pathname ===
        baseVrbt + "dashboard/content-management/content-class") &&
    isParent
      ? "bg-[#B90024] text-white_A700 mb-2"
      : "text-blue_gray_400";

  return (
    <NavLink
      to={to}
      className={`flex items-center gap-3.5 px-5 py-3 rounded-[10px] ${
        checkPath(to) ? "bg-[#B90024] text-white" : "bg-white_A700"
      } ${validatePath()} ${
        (location.pathname === to ||
          location.pathname === baseVrbt + "dashboard/content-management") &&
        to === baseVrbt + "dashboard/content-management/content-list" &&
        isSubMenu &&
        "bg-red-100 text-[#B90024] font-bold"
      } ${
        location.pathname === to &&
        isSubMenu &&
        "bg-red-100 text-[#B90024] font-bold"
      }`}
      end
    >
      {!isSubMenu && (
        <Img
          src={
            checkPath(to)
              ? iconActiveURL
              : !checkPathSub()
              ? iconInActiveURL
              : iconActiveURL
          }
          className="h-7 w-[25px]"
          alt="icon"
        />
      )}
      <Text
        className={`font-medium font-poppins text-left w-auto ${
          isSubMenu && "my-2"
        }`}
        as="h5"
        variant="h5"
      >
        {title}
      </Text>
    </NavLink>
  );
};

export default CustomLink;

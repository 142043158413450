import React, { useState } from "react";
import CustomLink from "./CustomLink";
import { Img, Text } from "components";
import { baseVrbt } from "app/constants";

const SideNav = ({ menus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  const toggleDropdown = (feature_name) => {
    setOpenMenu(openMenu === feature_name ? null : feature_name);
  };

  return (
    <>
      <div className="sticky top-0 hidden mdreal:flex flex-col gap-4 p-5 border-r w-[280px]">
        <div className="bg-white_A700 border-b w-[259px] border-gray_200 border-solid flex flex-wrap justify-between items-center mx-auto max-w-screen-xlw-full">
          <div className="flex flex-row gap-3.5 items-center mb-3 justify-start self-stretch w-auto">
            <Img
              src={process.env.PUBLIC_URL + "/images/vrbt/ic_logo_vrbt.svg"}
              className="h-[50px] w-[180px] ml-4 object-cover"
              alt="VIDEO RBT"
            />
          </div>
        </div>

        {menus.map((data) => (
          <MenuItem
            key={data.feature_name}
            data={data}
            isOpen={openMenu === data.feature_name}
            sub={data.feature_name}
            toggleDropdown={toggleDropdown}
          />
        ))}

        {/* {menus.map((data) => (
          <CustomLink
            key={data.feature_code}
            to={
              data.feature_name === "Dashboard"
                ? "/dashboard"
                : "/dashboard/" +
                  data.feature_name.toLowerCase().replace(/\s/g, "-")
            }
            title={data.feature_name}
            iconActiveURL={data.icon_active}
            iconInActiveURL={data.icon_incative}
          />
        ))} */}
      </div>
    </>
  );
};

const MenuItem = ({ data, isOpen, sub, toggleDropdown }) => {
  return (
    <div>
      <div onClick={() => toggleDropdown(data.feature_name)}>
        <CustomLink
          key={data.feature_name}
          to={
            data.feature_name === "Dashboard"
              ? baseVrbt + "dashboard"
              : baseVrbt +
                "dashboard/" +
                data.feature_name.toLowerCase().replace(/\s/g, "-")
          }
          title={data.feature_name}
          iconActiveURL={data.icon_active}
          iconInActiveURL={data.icon_inactive}
          isSubMenu={false}
          isParent={data.feature_name === "Content Management"}
        />
      </div>

      {isOpen && (
        <div>
          {data.sub_menu &&
            data.sub_menu.map((subItem) => (
              <CustomLink
                key={subItem.feature_name}
                to={`${baseVrbt}dashboard/${sub
                  .toLowerCase()
                  .replace(/\s/g, "-")}/${subItem.feature_name
                  .toLowerCase()
                  .replace(/\s/g, "-")}`}
                title={subItem.feature_name}
                iconActiveURL={subItem.icon_active}
                iconInActiveURL={subItem.icon_inactive}
                isSubMenu={true}
                isParent={false}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default SideNav;

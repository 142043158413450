import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./constants";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // sign in
    signIn: builder.mutation({
      query: ({ email, password }) => ({
        url: `sign-in`,
        method: "POST",
        body: { email, password },
      }),
    }),
    // sign up
    signUp: builder.mutation({
      query: (data) => ({
        url: `sign-up`,
        method: "POST",
        body: data,
      }),
    }),
    //   partner-account-detail
    partnerAccountDetail: builder.mutation({
      query: ({ id }) => ({
        url: `partner-account-detail?id=${id}`,
        method: "GET",
      }),
    }),
    // get bank
    getBank: builder.mutation({
      query: () => ({
        url: `banks`,
        method: "GET",
      }),
    }),
    // get province and city
    getProvince: builder.mutation({
      query: () => ({
        url: `get-province-and-city`,
        method: "GET",
      }),
    }),
    // get artist
    getArtist: builder.mutation({
      query: ({ id }) => ({
        url: `get-artist?id=${id}`,
        method: "GET",
      }),
    }),
    // create artist
    createArtist: builder.mutation({
      query: (data) => ({
        url: `create-artist`,
        method: "POST",
        body: data,
      }),
    }),
    // update artist
    updateArtist: builder.mutation({
      query: (data) => ({
        url: `update-artist`,
        method: "PUT",
        body: data,
      }),
    }),
    // get all partner management
    getAllPartnerManagement: builder.mutation({
      query: ({ id }) => ({
        url: `partner-management?id=${id}`,
        method: "GET",
      }),
    }),
    // partner assesment
    partnerAssesment: builder.mutation({
      query: (data) => ({
        url: `partner-assesment`,
        method: "POST",
        body: data,
      }),
    }),
    // parner deactivate
    partnerDeactivate: builder.mutation({
      query: (data) => ({
        url: `partner-deactivate`,
        method: "POST",
        body: data,
      }),
    }),
    // get all inboxes
    getAllInboxes: builder.mutation({
      query: ({ uid }) => ({
        url: `get-all-messages?uid=${uid}`,
        method: "GET",
      }),
    }),
    // update inboxes
    updateInboxes: builder.mutation({
      query: (data) => ({
        url: "update-messages",
        method: "PUT",
        body: data,
      }),
    }),
    // check email
    checkEmail: builder.mutation({
      query: ({ email }) => ({
        url: `check-email?email=${email}`,
        method: "GET",
      }),
    }),
    // check company name
    checkCompanyName: builder.mutation({
      query: ({ company_name }) => ({
        url: `check-company-name?company_name=${company_name}`,
        method: "GET",
      }),
    }),
    // get categories content
    getCategoriesContent: builder.mutation({
      query: () => ({
        url: `get-categories`,
        method: "GET",
      }),
    }),
    // get content type
    getContentType: builder.mutation({
      query: () => ({
        url: `get-content-type`,
        method: "GET",
      }),
    }),
    // get subs plan
    getSubsPlan: builder.mutation({
      query: () => ({
        url: `get-subs-plan`,
        method: "GET",
      }),
    }),
    //add content classses
    addContentClass: builder.mutation({
      query: (data) => ({
        url: "add-content-classes",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    //get content classes
    getContentClasses: builder.mutation({
      query: ({ id }) => ({
        url: `get-content-classes/`,
        method: "GET",
      }),
    }),
    getContentClass: builder.query({
      query: ({ search, status, sort, page = 1 }) =>
        `get-content-classes?search=${search}&page=${page}&status=${status}&sort=${sort}`,
      providesTags: ["content-class"],
    }),
    // edit content classes
    editContentClasses: builder.mutation({
      query: (data) => ({
        url: `edit-content-classes`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    editNameContentClasses: builder.mutation({
      query: (data) => ({
        url: `edit-name-content-classes`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    editContentClassDetail: builder.mutation({
      query: (data) => ({
        url: `edit-content-class-detail`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    deleteContentClassDetail: builder.mutation({
      query: (data) => ({
        url: "delete-content-class-detail",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    // get content list
    getContentList: builder.mutation({
      query: ({
        id,
        partner_id = "",
        artist_id = "",
        type = "",
        status = "",
      }) => ({
        url: `get-content-list?id=${id}&partner_id=${partner_id}&artist_id=${artist_id}&type=${type}&status=${status}`,
        method: "GET",
      }),
    }),
    getContentClassDetail: builder.query({
      query: ({ page = 1, id }) =>
        `get-content-class-detail/${id}?page=${page}`,
      providesTags: ["content-class"],
    }),
    getContentGroupDetail: builder.mutation({
      query: ({ page = 1, id }) => ({
        url: `get-content-class-detail/${id}?page=${page}`,
        method: "GET",
      }),
    }),
    //add content list detail
    addContentClassDetail: builder.mutation({
      query: (data) => ({
        url: "add-content-class-detail",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["content-class"],
    }),
    // create content
    createContent: builder.mutation({
      query: (data) => ({
        url: "create-content",
        method: "POST",
        body: data,
      }),
    }),
    // content detail
    contentDetail: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-content-detail?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // get price by content id
    getPriceContent: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-price-content?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // accept content
    acceptContent: builder.mutation({
      query: (data) => ({
        url: `accept-content`,
        method: "POST",
        body: data,
      }),
    }),
    // reject content
    rejectContent: builder.mutation({
      query: (data) => ({
        url: `reject-content`,
        method: "POST",
        body: data,
      }),
    }),
    // update status content
    changeStatusContent: builder.mutation({
      query: (data) => ({
        url: `change-content-status`,
        method: "PUT",
        body: data,
      }),
    }),
    // get content like
    getContentLike: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-content-like?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // get content report
    getContentReport: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-content-report?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // update data content
    updateDataContent: builder.mutation({
      query: (data) => ({
        url: `edit-content`,
        method: "PUT",
        body: data,
      }),
    }),
    // update pic
    updateDataPic: builder.mutation({
      query: (data) => ({
        url: `edit-partner-pic`,
        method: "PUT",
        body: data,
      }),
    }),
    // extend partnership
    extendPartnership: builder.mutation({
      query: (data) => ({
        url: `extend-partnership`,
        method: "PUT",
        body: data,
      }),
    }),
    // REPORT//
    // Revenue Report
    revenueReport: builder.mutation({
      query: (data) => ({
        url: `revenue-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Purchase Report
    purchaseReport: builder.mutation({
      query: (data) => ({
        url: `purchase-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Content Report
    contentReport: builder.mutation({
      query: (data) => ({
        url: `content-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Subscription Report
    subscriptionReport: builder.mutation({
      query: (data) => ({
        url: `subscription-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Like & Report
    likeAndReport: builder.mutation({
      query: (data) => ({
        url: `like-and-report`,
        method: "POST",
        body: data,
      }),
    }),
    // DR Report
    drReport: builder.mutation({
      query: (data) => ({
        url: `dr-report`,
        method: "POST",
        body: data,
      }),
    }),
    // Summary Report
    summaryReport: builder.mutation({
      query: (data) => ({
        url: `summary-report`,
        method: "POST",
        body: data,
      }),
    }),
    // REPORT//
  }),
  tagTypes: ["content-class"],
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useGetProvinceMutation,
  usePartnerAccountDetailMutation,
  useGetBankMutation,
  useGetArtistMutation,
  useCreateArtistMutation,
  useUpdateArtistMutation,
  useGetAllPartnerManagementMutation,
  usePartnerAssesmentMutation,
  usePartnerDeactivateMutation,
  useGetAllInboxesMutation,
  useUpdateInboxesMutation,
  useCheckEmailMutation,
  useCheckCompanyNameMutation,
  useGetCategoriesContentMutation,
  useGetContentListMutation,
  useGetContentTypeMutation,
  useGetSubsPlanMutation,
  useCreateContentMutation,
  useContentDetailMutation,
  useGetPriceContentMutation,
  useAcceptContentMutation,
  useRejectContentMutation,
  useChangeStatusContentMutation,
  useGetContentLikeMutation,
  useGetContentReportMutation,
  useUpdateDataContentMutation,
  useUpdateDataPicMutation,
  useExtendPartnershipMutation,
  // content class
  useAddContentClassDetailMutation,
  useGetContentClassDetailQuery,
  useGetContentClassesMutation,
  useGetContentClassQuery,
  useAddContentClassMutation,
  useEditContentClassesMutation,
  useEditNameContentClassesMutation,
  useEditContentClassDetailMutation,
  useDeleteContentClassDetailMutation,
  useGetContentGroupDetailMutation,
  // report
  useRevenueReportMutation,
  useContentReportMutation,
  useDrReportMutation,
  useLikeAndReportMutation,
  useSummaryReportMutation,
  usePurchaseReportMutation,
  useSubscriptionReportMutation,
} = api;
